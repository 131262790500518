exports.components = {
  "component---1755073352-dustries-write-a-piece-talking-through-the-ups-and-downs-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/advantages-and-disadvantages-of-being-a-freelancer-maybe-you-work-in-a-sector-which-relies-heavily-on-freelance-staff-such-as-construction-it-or-the-creative-industries-write-a-piece-talking-through-the-ups-and-downs.mdx" /* webpackChunkName: "component---1755073352-dustries-write-a-piece-talking-through-the-ups-and-downs-mdx" */),
  "component---1788359781-aily-basis-so-talk-your-target-clients-through-your-usps-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-benefits-of-using-a-recruitment-company-make-a-strong-case-for-your-agencys-value-im-sure-you-sell-your-agency-on-a-daily-basis-so-talk-your-target-clients-through-your-usps.mdx" /* webpackChunkName: "component---1788359781-aily-basis-so-talk-your-target-clients-through-your-usps-mdx" */),
  "component---184761720-ng-or-working-with-staff-who-live-in-different-countries-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-tips-on-hiring-remote-workers-ever-since-2020-remote-work-has-really-taken-off-and-more-and-more-companies-are-open-to-outsourcing-or-working-with-staff-who-live-in-different-countries.mdx" /* webpackChunkName: "component---184761720-ng-or-working-with-staff-who-live-in-different-countries-mdx" */),
  "component---1898181097-ai-tools-crms-ats-cv-parsing-tools-ai-matching-tools-etc-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-technology-in-modern-recruitment-whats-some-of-the-rectech-you-currently-use-in-your-agency-what-could-help-your-clients-talk-about-ai-tools-crms-ats-cv-parsing-tools-ai-matching-tools-etc.mdx" /* webpackChunkName: "component---1898181097-ai-tools-crms-ats-cv-parsing-tools-ai-matching-tools-etc-mdx" */),
  "component---2653146912-o-well-what-positive-and-negative-stories-have-you-heard-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-tips-for-onboarding-a-new-hire-share-strategies-for-successful-employee-integration-what-do-the-best-companies-do-well-what-positive-and-negative-stories-have-you-heard.mdx" /* webpackChunkName: "component---2653146912-o-well-what-positive-and-negative-stories-have-you-heard-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-common-mistakes-hiring-managers-make-during-the-interview-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-common-mistakes-hiring-managers-make-during-the-interview-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-common-mistakes-hiring-managers-make-during-the-interview-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-questions-to-ask-applicants-at-the-final-face-to-face-stage-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-questions-to-ask-applicants-at-the-final-face-to-face-stage.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-questions-to-ask-applicants-at-the-final-face-to-face-stage-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-questions-to-ask-applicants-at-the-first-face-to-face-stage-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-questions-to-ask-applicants-at-the-first-face-to-face-stage.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-questions-to-ask-applicants-at-the-first-face-to-face-stage-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-questions-to-ask-applicants-at-the-telephone-stage-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-questions-to-ask-applicants-at-the-telephone-stage.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-questions-to-ask-applicants-at-the-telephone-stage-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-tips-for-a-winning-linkedin-profile-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-tips-for-a-winning-linkedin-profile.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-tips-for-a-winning-linkedin-profile-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-top-podcasts-every-hiring-manager-should-listen-to-put-together-a-list-of-some-of-the-most-prominent-industry-and-recruitment-podcasts-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-top-podcasts-every-hiring-manager-should-listen-to-put-together-a-list-of-some-of-the-most-prominent-industry-and-recruitment-podcasts.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-top-podcasts-every-hiring-manager-should-listen-to-put-together-a-list-of-some-of-the-most-prominent-industry-and-recruitment-podcasts-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-ways-to-ensure-an-efficient-interview-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-ways-to-ensure-an-efficient-interview-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-ways-to-ensure-an-efficient-interview-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-ways-to-expand-your-network-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/10-ways-to-expand-your-network.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-10-ways-to-expand-your-network-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-3-d-printing-innovations-discuss-the-latest-developments-in-3-d-printing-technology-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/3d-printing-innovations-discuss-the-latest-developments-in-3d-printing-technology.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-3-d-printing-innovations-discuss-the-latest-developments-in-3-d-printing-technology-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-5-simple-tips-for-choosing-the-right-recruitment-agency-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/5-simple-tips-for-choosing-the-right-recruitment-agency.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-5-simple-tips-for-choosing-the-right-recruitment-agency-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-a-beginners-guide-to-event-storming-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/a-beginners-guide-to-event-storming.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-a-beginners-guide-to-event-storming-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-are-your-hiring-managers-emotionally-intelligent-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/are-your-hiring-managers-emotionally-intelligent.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-are-your-hiring-managers-emotionally-intelligent-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-augmented-reality-in-business-how-it-is-bringing-imaginations-to-life-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/augmented-reality-in-business-how-it-is-bringing-imaginations-to-life.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-augmented-reality-in-business-how-it-is-bringing-imaginations-to-life-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-augmented-reality-in-everyday-life-how-ar-can-transform-various-industries-and-personal-experiences-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/augmented-reality-in-everyday-life-how-ar-can-transform-various-industries-and-personal-experiences.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-augmented-reality-in-everyday-life-how-ar-can-transform-various-industries-and-personal-experiences-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-bdd-tdd-and-atdd-whats-the-difference-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/bdd-tdd-and-atdd:-what's-the-difference.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-bdd-tdd-and-atdd-whats-the-difference-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-benefits-of-outsourcing-it-services-for-small-and-medium-businesses-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/benefits-of-outsourcing-it-services-for-small-and-medium-businesses.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-benefits-of-outsourcing-it-services-for-small-and-medium-businesses-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-best-practices-for-it-security-in-financial-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/best-practices-for-it-security-in-financial-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-best-practices-for-it-security-in-financial-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-bioinformatics-and-it-bridging-the-gap-between-technology-and-life-sciences-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/bioinformatics-and-it-bridging-the-gap-between-technology-and-life-sciences.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-bioinformatics-and-it-bridging-the-gap-between-technology-and-life-sciences-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-biotechnology-breakthroughs-discuss-recent-innovations-in-biotech-and-their-implications-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/biotechnology-breakthroughs-discuss-recent-innovations-in-biotech-and-their-implications.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-biotechnology-breakthroughs-discuss-recent-innovations-in-biotech-and-their-implications-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-blockchain-beyond-bitcoin-explore-uses-of-blockchain-technology-outside-of-cryptocurrency-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/blockchain-beyond-bitcoin-explore-uses-of-blockchain-technology-outside-of-cryptocurrency.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-blockchain-beyond-bitcoin-explore-uses-of-blockchain-technology-outside-of-cryptocurrency-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-building-a-personal-brand-how-to-stand-out-in-the-job-market-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/building-a-personal-brand-how-to-stand-out-in-the-job-market.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-building-a-personal-brand-how-to-stand-out-in-the-job-market-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-building-a-resilient-it-infrastructure-for-your-company-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/building-a-resilient-it-infrastructure-for-your-company.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-building-a-resilient-it-infrastructure-for-your-company-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-building-a-strong-employer-brand-to-attract-top-talent-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/building-a-strong-employer-brand-to-attract-top-talent.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-building-a-strong-employer-brand-to-attract-top-talent-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cloud-computing-and-its-future-discuss-the-impact-and-future-of-cloud-computing-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/cloud-computing-and-its-future-discuss-the-impact-and-future-of-cloud-computing.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cloud-computing-and-its-future-discuss-the-impact-and-future-of-cloud-computing-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cloud-vs-on-premise-solutions-which-is-right-for-your-business-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/cloud-vs-on-premise-solutions-which-is-right-for-your-business.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cloud-vs-on-premise-solutions-which-is-right-for-your-business-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-common-mistakes-to-avoid-in-the-recruitment-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/common-mistakes-to-avoid-in-the-recruitment-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-common-mistakes-to-avoid-in-the-recruitment-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cost-saving-tips-for-implementing-it-services-in-your-business-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/cost-saving-tips-for-implementing-it-services-in-your-business.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cost-saving-tips-for-implementing-it-services-in-your-business-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-create-a-free-private-npm-repository-on-aws-with-codeartifact-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/create-a-free-private-npm-repository-on-aws-with-codeartifact.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-create-a-free-private-npm-repository-on-aws-with-codeartifact-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-creating-a-robust-it-support-system-for-your-company-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/creating-a-robust-it-support-system-for-your-company.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-creating-a-robust-it-support-system-for-your-company-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-creating-an-inclusive-hiring-process-to-broaden-your-talent-pool-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/creating-an-inclusive-hiring-process-to-broaden-your-talent-pool.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-creating-an-inclusive-hiring-process-to-broaden-your-talent-pool-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cyber-warfare-explore-the-implications-of-technology-in-modern-warfare-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/cyber-warfare-explore-the-implications-of-technology-in-modern-warfare.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cyber-warfare-explore-the-implications-of-technology-in-modern-warfare-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cybersecurity-best-practices-for-remote-workers-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/cybersecurity-best-practices-for-remote-workers.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cybersecurity-best-practices-for-remote-workers-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cybersecurity-in-the-modern-world-share-insights-on-protecting-digital-privacy-in-an-increasingly-online-world-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/cybersecurity-in-the-modern-world-share-insights-on-protecting-digital-privacy-in-an-increasingly-online-world.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-cybersecurity-in-the-modern-world-share-insights-on-protecting-digital-privacy-in-an-increasingly-online-world-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-data-privacy-and-protection-ensuring-compliance-with-regulations-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/data-privacy-and-protection:-ensuring-compliance-with-regulations.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-data-privacy-and-protection-ensuring-compliance-with-regulations-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-data-privacy-concerns-debate-the-balance-between-technological-convenience-and-privacy-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/data-privacy-concerns-debate-the-balance-between-technological-convenience-and-privacy.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-data-privacy-concerns-debate-the-balance-between-technological-convenience-and-privacy-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-digital-currency-trends-explore-the-future-of-digital-currencies-and-their-potential-impact-on-global-economies-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/digital-currency-trends-explore-the-future-of-digital-currencies-and-their-potential-impact-on-global-economies.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-digital-currency-trends-explore-the-future-of-digital-currencies-and-their-potential-impact-on-global-economies-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-digital-ethics-ensuring-responsible-use-of-technology-in-business-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/digital-ethics:-ensuring-responsible-use-of-technology-in-business.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-digital-ethics-ensuring-responsible-use-of-technology-in-business-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-digital-nomad-lifestyle-how-has-technology-enabled-the-rise-of-digital-nomadism-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/digital-nomad-lifestyle-how-has-technology-enabled-the-rise-of-digital-nomadism.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-digital-nomad-lifestyle-how-has-technology-enabled-the-rise-of-digital-nomadism-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-digital-twins-the-revolutionary-approach-to-business-innovation-and-efficiency-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/digital-twins-the-revolutionary-approach-to-business-innovation-and-efficiency.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-digital-twins-the-revolutionary-approach-to-business-innovation-and-efficiency-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-e-commerce-evolution-analyze-how-online-shopping-is-evolving-with-technology-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/e-commerce-evolution-analyze-how-online-shopping-is-evolving-with-technology.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-e-commerce-evolution-analyze-how-online-shopping-is-evolving-with-technology-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-effective-data-management-strategies-for-businesses-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/effective-data-management-strategies-for-businesses.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-effective-data-management-strategies-for-businesses-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-enhancing-business-productivity-with-it-support-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/enhancing-business-productivity-with-it-support-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-enhancing-business-productivity-with-it-support-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-enhancing-customer-experience-with-it-solutions-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/enhancing-customer-experience-with-it-solutions.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-enhancing-customer-experience-with-it-solutions-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-enhancing-network-security-top-strategies-for-businesses-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/enhancing-network-security:-top-strategies-for-businesses.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-enhancing-network-security-top-strategies-for-businesses-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-ethical-hacking-explore-the-role-of-ethical-hacking-in-cybersecurity-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/ethical-hacking-explore-the-role-of-ethical-hacking-in-cybersecurity.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-ethical-hacking-explore-the-role-of-ethical-hacking-in-cybersecurity-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-exploring-azures-advanced-networking-solutions-for-enhanced-connectivity-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/exploring-azures-advanced-networking-solutions-for-enhanced-connectivity.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-exploring-azures-advanced-networking-solutions-for-enhanced-connectivity-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-from-blockchain-to-business-chains-revolutionizing-industry-operations-with-it-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/from-blockchain-to-business-chains-revolutionizing-industry-operations-with-it.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-from-blockchain-to-business-chains-revolutionizing-industry-operations-with-it-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-harnessing-the-power-of-quantum-computing-the-next-frontier-in-it-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/harnessing-the-power-of-quantum-computing-the-next-frontier-in-it-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-harnessing-the-power-of-quantum-computing-the-next-frontier-in-it-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-hot-recruitment-industry-topics-stay-ahead-of-the-curve-with-regular-industry-updates-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/hot-recruitment-industry-topics-stay-ahead-of-the-curve-with-regular-industry-updates.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-hot-recruitment-industry-topics-stay-ahead-of-the-curve-with-regular-industry-updates-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-digital-transformation-is-changing-the-way-we-work-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-digital-transformation-is-changing-the-way-we-work.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-digital-transformation-is-changing-the-way-we-work-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-it-is-transforming-sustainable-energy-solutions-globally-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-it-is-transforming-sustainable-energy-solutions-globally.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-it-is-transforming-sustainable-energy-solutions-globally-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-it-services-can-enhance-your-workflow-efficiency-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-it-services-can-enhance-your-workflow-efficiency.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-it-services-can-enhance-your-workflow-efficiency-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-it-services-can-support-business-continuity-plans-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-it-services-can-support-business-continuity-plans.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-it-services-can-support-business-continuity-plans-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-it-services-enhance-collaboration-and-communication-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-it-services-enhance-collaboration-and-communication.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-it-services-enhance-collaboration-and-communication-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-remote-work-is-transforming-the-it-industry-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-remote-work-is-transforming-the-it-industry.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-remote-work-is-transforming-the-it-industry-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-ab-test-your-website-like-a-pro-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-ab-test-your-website-like-a-pro.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-ab-test-your-website-like-a-pro-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-attract-gen-z-candidates-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-attract-gen-z-candidates.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-attract-gen-z-candidates-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-choose-the-right-it-services-provider-for-your-business-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-choose-the-right-it-services-provider-for-your-business.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-choose-the-right-it-services-provider-for-your-business-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-choose-the-right-recruitment-company-for-your-business-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-choose-the-right-recruitment-company-for-your-business.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-choose-the-right-recruitment-company-for-your-business-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-communicate-your-companys-values-mission-statement-and-purpose-to-candidates-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-communicate-your-companys-values-mission-statement-and-purpose-to-candidates.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-communicate-your-companys-values-mission-statement-and-purpose-to-candidates-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-create-an-effective-employee-referral-program-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-create-an-effective-employee-referral-program.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-create-an-effective-employee-referral-program-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-digital-transform-your-business-with-it-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-digital-transform-your-business-with-it-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-digital-transform-your-business-with-it-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-effectively-outsource-your-it-needs-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-effectively-outsource-your-it-needs.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-effectively-outsource-your-it-needs-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-effectively-use-social-media-for-marketing-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-effectively-use-social-media-for-marketing.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-effectively-use-social-media-for-marketing-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-negotiate-for-a-pay-rise-and-how-to-handle-the-aftermath-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-negotiate-for-a-pay-rise-and-how-to-handle-the-aftermath.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-negotiate-for-a-pay-rise-and-how-to-handle-the-aftermath-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-optimize-it-infrastructure-for-your-business-growth-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-optimize-it-infrastructure-for-your-business-growth.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-optimize-it-infrastructure-for-your-business-growth-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-optimize-website-ux-for-seo-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-optimize-website-ux-for-seo.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-optimize-website-ux-for-seo-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-safeguard-your-business-with-comprehensive-it-security-solutions-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-safeguard-your-business-with-comprehensive-it-security-solutions.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-safeguard-your-business-with-comprehensive-it-security-solutions-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-write-job-adverts-that-attract-talent-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/how-to-write-job-adverts-that-attract-talent.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-how-to-write-job-adverts-that-attract-talent-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-impact-of-5-g-technology-explore-the-potential-changes-5-g-will-bring-to-connectivity-and-everyday-life-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/impact-of-5g-technology-explore-the-potential-changes-5g-will-bring-to-connectivity-and-everyday-life.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-impact-of-5-g-technology-explore-the-potential-changes-5-g-will-bring-to-connectivity-and-everyday-life-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-implementing-a-disaster-recovery-plan-what-you-need-to-know-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/implementing-a-disaster-recovery-plan:-what-you-need-to-know.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-implementing-a-disaster-recovery-plan-what-you-need-to-know-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-iot-and-its-implications-for-business-it-infrastructure-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/iot-and-its-implications-for-business-it-infrastructure.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-iot-and-its-implications-for-business-it-infrastructure-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-it-service-level-agreements-what-to-look-for-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/it-service-level-agreements:-what-to-look-for.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-it-service-level-agreements-what-to-look-for-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-leveraging-big-data-analytics-for-business-growth-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/leveraging-big-data-analytics-for-business-growth.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-leveraging-big-data-analytics-for-business-growth-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-leveraging-online-learning-platforms-for-career-advancement-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/leveraging-online-learning-platforms-for-career-advancement.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-leveraging-online-learning-platforms-for-career-advancement-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-leveraging-technology-to-streamline-your-recruitment-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/leveraging-technology-to-streamline-your-recruitment-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-leveraging-technology-to-streamline-your-recruitment-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-mastering-new-skills-the-key-to-10-x-your-career-growth-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/mastering-new-skills-the-key-to-10x-your-career-growth.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-mastering-new-skills-the-key-to-10-x-your-career-growth-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-mastering-solid-principles-in-object-oriented-programming-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/mastering-solid-principles-in-object-oriented-programming.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-mastering-solid-principles-in-object-oriented-programming-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-maximizing-your-roi-with-managed-it-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/maximizing-your-roi-with-managed-it-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-maximizing-your-roi-with-managed-it-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-mobile-technology-evolution-discuss-the-evolution-of-mobile-technology-and-its-future-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/mobile-technology-evolution-discuss-the-evolution-of-mobile-technology-and-its-future.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-mobile-technology-evolution-discuss-the-evolution-of-mobile-technology-and-its-future-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-networking-like-a-pro-expanding-your-professional-connections-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/networking-like-a-pro-expanding-your-professional-connections.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-networking-like-a-pro-expanding-your-professional-connections-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-optimizing-cost-and-performance-with-aws-ec-2-auto-scaling-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/optimizing-cost-and-performance-with-aws-ec2-auto-scaling.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-optimizing-cost-and-performance-with-aws-ec-2-auto-scaling-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-quantum-computing-what-is-it-and-how-could-it-change-computing-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/quantum-computing-what-is-it-and-how-could-it-change-computing.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-quantum-computing-what-is-it-and-how-could-it-change-computing-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-robotics-in-daily-life-how-are-robots-becoming-a-part-of-everyday-life-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/robotics-in-daily-life-how-are-robots-becoming-a-part-of-everyday-life.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-robotics-in-daily-life-how-are-robots-becoming-a-part-of-everyday-life-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-roles-and-responsibilities-of-managed-it-service-providers-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/roles-and-responsibilities-of-managed-it-service-providers.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-roles-and-responsibilities-of-managed-it-service-providers-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-scalable-it-solutions-for-growing-enterprises-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/scalable-it-solutions-for-growing-enterprises.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-scalable-it-solutions-for-growing-enterprises-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-screamingfrog-a-tool-for-seo-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/screamingfrog-a-tool-for-seo.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-screamingfrog-a-tool-for-seo-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-securing-your-data-on-aws-best-practices-for-cloud-security-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/securing-your-data-on-aws-best-practices-for-cloud-security.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-securing-your-data-on-aws-best-practices-for-cloud-security-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-self-driving-cars-explore-the-advancements-and-challenges-in-autonomous-vehicle-technology-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/self-driving-cars-explore-the-advancements-and-challenges-in-autonomous-vehicle-technology.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-self-driving-cars-explore-the-advancements-and-challenges-in-autonomous-vehicle-technology-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-smart-homes-and-iot-analyze-the-growing-trend-of-smart-homes-and-the-internet-of-things-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/smart-homes-and-iot-analyze-the-growing-trend-of-smart-homes-and-the-internet-of-things.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-smart-homes-and-iot-analyze-the-growing-trend-of-smart-homes-and-the-internet-of-things-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-social-media-and-politics-analyze-the-impact-of-social-media-on-political-discourse-and-elections-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/social-media-and-politics-analyze-the-impact-of-social-media-on-political-discourse-and-elections.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-social-media-and-politics-analyze-the-impact-of-social-media-on-political-discourse-and-elections-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-soft-skills-that-can-make-you-more-hireable-in-any-industry-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/soft-skills-that-can-make-you-more-hireable-in-any-industry.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-soft-skills-that-can-make-you-more-hireable-in-any-industry-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-sustainable-tech-discuss-innovations-in-technology-that-contribute-to-environmental-sustainability-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/sustainable-tech-discuss-innovations-in-technology-that-contribute-to-environmental-sustainability.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-sustainable-tech-discuss-innovations-in-technology-that-contribute-to-environmental-sustainability-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-and-accessibility-explore-how-technology-is-breaking-barriers-for-people-with-disabilities-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-and-accessibility-explore-how-technology-is-breaking-barriers-for-people-with-disabilities.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-and-accessibility-explore-how-technology-is-breaking-barriers-for-people-with-disabilities-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-and-mental-health-explore-the-relationship-between-technology-use-and-mental-well-being-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-and-mental-health-explore-the-relationship-between-technology-use-and-mental-well-being.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-and-mental-health-explore-the-relationship-between-technology-use-and-mental-well-being-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-giants-impact-analyze-the-influence-of-companies-like-google-amazon-and-apple-on-everyday-life-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-giants-impact-analyze-the-influence-of-companies-like-google-amazon-and-apple-on-everyday-life.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-giants-impact-analyze-the-influence-of-companies-like-google-amazon-and-apple-on-everyday-life-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-agriculture-discuss-innovations-in-technology-that-are-transforming-agriculture-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-in-agriculture-discuss-innovations-in-technology-that-are-transforming-agriculture.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-agriculture-discuss-innovations-in-technology-that-are-transforming-agriculture-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-art-and-design-how-is-technology-influencing-contemporary-art-and-design-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-in-art-and-design-how-is-technology-influencing-contemporary-art-and-design.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-art-and-design-how-is-technology-influencing-contemporary-art-and-design-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-disaster-response-how-can-technology-aid-in-natural-disaster-prediction-and-response-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-in-disaster-response-how-can-technology-aid-in-natural-disaster-prediction-and-response.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-disaster-response-how-can-technology-aid-in-natural-disaster-prediction-and-response-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-education-discuss-the-role-and-impact-of-technology-in-modern-education-systems-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-in-education-discuss-the-role-and-impact-of-technology-in-modern-education-systems.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-education-discuss-the-role-and-impact-of-technology-in-modern-education-systems-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-food-industry-how-is-technology-changing-food-production-and-distribution-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-in-food-industry-how-is-technology-changing-food-production-and-distribution.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-food-industry-how-is-technology-changing-food-production-and-distribution-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-healthcare-how-is-technology-revolutionizing-healthcare-delivery-and-patient-care-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-in-healthcare-how-is-technology-revolutionizing-healthcare-delivery-and-patient-care.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-healthcare-how-is-technology-revolutionizing-healthcare-delivery-and-patient-care-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-space-exploration-discuss-recent-technological-advancements-in-space-exploration-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-in-space-exploration-discuss-recent-technological-advancements-in-space-exploration.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-in-space-exploration-discuss-recent-technological-advancements-in-space-exploration-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-policy-and-regulation-debate-the-need-and-challenges-of-regulating-technology-effectively-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-policy-and-regulation-debate-the-need-and-challenges-of-regulating-technology-effectively.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-policy-and-regulation-debate-the-need-and-challenges-of-regulating-technology-effectively-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-startups-and-venture-capital-analyze-the-relationship-between-tech-startups-and-venture-capital-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-startups-and-venture-capital-analyze-the-relationship-between-tech-startups-and-venture-capital.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-startups-and-venture-capital-analyze-the-relationship-between-tech-startups-and-venture-capital-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-startups-to-watch-highlight-promising-startups-and-what-makes-them-stand-out-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-startups-to-watch-highlight-promising-startups-and-what-makes-them-stand-out.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-startups-to-watch-highlight-promising-startups-and-what-makes-them-stand-out-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-trends-in-education-discuss-emerging-tech-trends-in-education-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tech-trends-in-education-discuss-emerging-tech-trends-in-education.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tech-trends-in-education-discuss-emerging-tech-trends-in-education-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-temporary-jobs-vs-permanent-jobs-another-pros-and-cons-article-on-temporary-and-permanent-roles-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/temporary-jobs-vs-permanent-jobs-another-pros-and-cons-article-on-temporary-and-permanent-roles.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-temporary-jobs-vs-permanent-jobs-another-pros-and-cons-article-on-temporary-and-permanent-roles-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-cloud-computing-for-businesses-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-benefits-of-cloud-computing-for-businesses.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-cloud-computing-for-businesses-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-conducting-regular-salary-and-benefit-reviews-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-benefits-of-conducting-regular-salary-and-benefit-reviews.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-conducting-regular-salary-and-benefit-reviews-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-using-a-recruitment-company-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-benefits-of-using-a-recruitment-company.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-using-a-recruitment-company-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-using-an-applicant-tracking-system-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-benefits-of-using-an-applicant-tracking-system.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-using-an-applicant-tracking-system-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-using-an-external-recruitment-agency-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-benefits-of-using-an-external-recruitment-agency.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-benefits-of-using-an-external-recruitment-agency-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-best-employee-retention-strategies-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-best-employee-retention-strategies.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-best-employee-retention-strategies-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-ethics-of-ai-debate-the-ethical-implications-of-ai-in-decision-making-processes-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-ethics-of-ai-debate-the-ethical-implications-of-ai-in-decision-making-processes.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-ethics-of-ai-debate-the-ethical-implications-of-ai-in-decision-making-processes-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-evolution-of-social-media-analyze-how-social-media-has-changed-communication-and-its-future-trajectory-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-evolution-of-social-media-analyze-how-social-media-has-changed-communication-and-its-future-trajectory.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-evolution-of-social-media-analyze-how-social-media-has-changed-communication-and-its-future-trajectory-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-ai-predict-how-artificial-intelligence-will-evolve-in-the-next-decade-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-future-of-ai-predict-how-artificial-intelligence-will-evolve-in-the-next-decade.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-ai-predict-how-artificial-intelligence-will-evolve-in-the-next-decade-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-cloud-computing-for-businesses-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-future-of-cloud-computing-for-businesses.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-cloud-computing-for-businesses-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-gaming-predict-how-technology-will-shape-the-future-of-video-games-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-future-of-gaming-predict-how-technology-will-shape-the-future-of-video-games.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-gaming-predict-how-technology-will-shape-the-future-of-video-games-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-it-emerging-technologies-to-watch-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-future-of-it:-emerging-technologies-to-watch.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-it-emerging-technologies-to-watch-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-transportation-predict-the-role-of-technology-in-shaping-the-future-of-transportation-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-future-of-transportation-predict-the-role-of-technology-in-shaping-the-future-of-transportation.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-transportation-predict-the-role-of-technology-in-shaping-the-future-of-transportation-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-work-predict-how-technology-will-continue-to-change-the-workforce-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-future-of-work-predict-how-technology-will-continue-to-change-the-workforce.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-future-of-work-predict-how-technology-will-continue-to-change-the-workforce-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-gig-economy-and-tech-how-has-technology-facilitated-the-rise-of-the-gig-economy-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-gig-economy-and-tech-how-has-technology-facilitated-the-rise-of-the-gig-economy.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-gig-economy-and-tech-how-has-technology-facilitated-the-rise-of-the-gig-economy-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-impact-of-5-g-on-business-it-solutions-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-impact-of-5g-on-business-it-solutions.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-impact-of-5-g-on-business-it-solutions-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-impact-of-the-gig-economy-on-recruitment-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-impact-of-the-gig-economy-on-recruitment.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-impact-of-the-gig-economy-on-recruitment-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-conducting-thorough-background-checks-on-job-candidates-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-importance-of-conducting-thorough-background-checks-on-job-candidates.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-conducting-thorough-background-checks-on-job-candidates-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-diversity-and-inclusion-in-the-hiring-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-importance-of-diversity-and-inclusion-in-the-hiring-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-diversity-and-inclusion-in-the-hiring-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-diversity-and-inclusion-in-the-hiring-process-we-all-know-by-now-that-diverse-teams-tend-to-mean-more-productive-teams-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-importance-of-diversity-and-inclusion-in-the-hiring-process-we-all-know-by-now-that-diverse-teams-tend-to-mean-more-productive-teams.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-diversity-and-inclusion-in-the-hiring-process-we-all-know-by-now-that-diverse-teams-tend-to-mean-more-productive-teams-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-ongoing-communication-with-candidates-throughout-the-recruitment-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-importance-of-ongoing-communication-with-candidates-throughout-the-recruitment-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-ongoing-communication-with-candidates-throughout-the-recruitment-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-ongoing-training-and-development-for-recruiters-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-importance-of-ongoing-training-and-development-for-recruiters.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-ongoing-training-and-development-for-recruiters-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-regular-it-audits-for-business-security-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-importance-of-regular-it-audits-for-business-security.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-importance-of-regular-it-audits-for-business-security-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-rise-of-edge-computing-transforming-real-time-data-processing-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-rise-of-edge-computing-transforming-real-time-data-processing.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-rise-of-edge-computing-transforming-real-time-data-processing-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-rise-of-remote-work-and-its-impact-on-recruitment-how-do-applicants-expectations-affect-the-hiring-process-and-your-ability-to-attract-talent-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-rise-of-remote-work-and-its-impact-on-recruitment-how-do-applicants-expectations-affect-the-hiring-process-and-your-ability-to-attract-talent.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-rise-of-remote-work-and-its-impact-on-recruitment-how-do-applicants-expectations-affect-the-hiring-process-and-your-ability-to-attract-talent-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-rise-of-remote-work-and-its-impact-on-recruitment-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-rise-of-remote-work-and-its-impact-on-recruitment.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-rise-of-remote-work-and-its-impact-on-recruitment-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-rise-of-remote-work-discuss-how-technology-has-reshaped-the-concept-of-the-workplace-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-rise-of-remote-work-discuss-how-technology-has-reshaped-the-concept-of-the-workplace.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-rise-of-remote-work-discuss-how-technology-has-reshaped-the-concept-of-the-workplace-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-ai-and-machine-learning-in-modern-it-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-ai-and-machine-learning-in-modern-it-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-ai-and-machine-learning-in-modern-it-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-ai-in-art-and-creativity-how-is-ai-changing-the-landscape-of-creative-industries-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-ai-in-art-and-creativity-how-is-ai-changing-the-landscape-of-creative-industries.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-ai-in-art-and-creativity-how-is-ai-changing-the-landscape-of-creative-industries-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-artificial-intelligence-in-recruitment-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-artificial-intelligence-in-recruitment.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-artificial-intelligence-in-recruitment-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-blockchain-in-business-it-solutions-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-blockchain-in-business-it-solutions.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-blockchain-in-business-it-solutions-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-company-values-in-attracting-and-retaining-top-talent-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-company-values-in-attracting-and-retaining-top-talent.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-company-values-in-attracting-and-retaining-top-talent-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-employer-branding-in-attracting-top-talent-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-employer-branding-in-attracting-top-talent.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-employer-branding-in-attracting-top-talent-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-networking-in-the-recruitment-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-networking-in-the-recruitment-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-networking-in-the-recruitment-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-recruiting-in-creating-a-positive-company-culture-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-recruiting-in-creating-a-positive-company-culture.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-recruiting-in-creating-a-positive-company-culture-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-social-media-in-modern-recruitment-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-social-media-in-modern-recruitment.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-social-media-in-modern-recruitment-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-social-media-in-modern-recruitment-practices-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-social-media-in-modern-recruitment-practices.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-social-media-in-modern-recruitment-practices-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-soft-skills-in-the-hiring-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-soft-skills-in-the-hiring-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-soft-skills-in-the-hiring-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-technology-in-modern-recruitment-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-role-of-technology-in-modern-recruitment.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-role-of-technology-in-modern-recruitment-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-top-skills-and-qualities-to-look-for-in-job-candidates-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-top-skills-and-qualities-to-look-for-in-job-candidates.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-top-skills-and-qualities-to-look-for-in-job-candidates-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-top-trends-in-the-recruitment-industry-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/the-top-trends-in-the-recruitment-industry.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-the-top-trends-in-the-recruitment-industry-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tips-for-effective-it-asset-management-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tips-for-effective-it-asset-management.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tips-for-effective-it-asset-management-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tips-for-secure-mobile-device-management-in-businesses-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tips-for-secure-mobile-device-management-in-businesses.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tips-for-secure-mobile-device-management-in-businesses-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tips-for-writing-an-effective-job-description-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/tips-for-writing-an-effective-job-description.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-tips-for-writing-an-effective-job-description-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-top-it-trends-shaping-the-future-of-businesses-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/top-it-trends-shaping-the-future-of-businesses.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-top-it-trends-shaping-the-future-of-businesses-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-transforming-your-business-with-aws-machine-learning-services-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/transforming-your-business-with-aws-machine-learning-services.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-transforming-your-business-with-aws-machine-learning-services-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-transforming-your-it-department-for-future-readiness-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/transforming-your-it-department-for-future-readiness.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-transforming-your-it-department-for-future-readiness-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-understanding-domain-driven-development-ddd-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/understanding-domain-driven-development-ddd.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-understanding-domain-driven-development-ddd-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-unleashing-the-power-of-aws-lambda-building-scalable-serverless-applications-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/unleashing-the-power-of-aws-lambda-building-scalable-serverless-applications.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-unleashing-the-power-of-aws-lambda-building-scalable-serverless-applications-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-utilizing-data-analytics-to-enhance-recruitment-strategies-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/utilizing-data-analytics-to-enhance-recruitment-strategies.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-utilizing-data-analytics-to-enhance-recruitment-strategies-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-utilizing-hybrid-it-solutions-for-optimal-performance-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/utilizing-hybrid-it-solutions-for-optimal-performance.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-utilizing-hybrid-it-solutions-for-optimal-performance-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-virtual-reality-experiences-describe-the-potential-of-vr-in-education-entertainment-or-other-fields-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/virtual-reality-experiences-describe-the-potential-of-vr-in-education-entertainment-or-other-fields.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-virtual-reality-experiences-describe-the-potential-of-vr-in-education-entertainment-or-other-fields-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-voice-assistants-and-ai-explore-the-impact-of-voice-assistants-in-daily-life-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/voice-assistants-and-ai-explore-the-impact-of-voice-assistants-in-daily-life.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-voice-assistants-and-ai-explore-the-impact-of-voice-assistants-in-daily-life-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-wearable-tech-discuss-the-evolution-and-future-of-wearable-technology-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/wearable-tech-discuss-the-evolution-and-future-of-wearable-technology.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-wearable-tech-discuss-the-evolution-and-future-of-wearable-technology-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-what-recruiters-look-for-in-cover-letters-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/what-recruiters-look-for-in-cover-letters.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-what-recruiters-look-for-in-cover-letters-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-why-a-hiring-process-built-on-diversity-means-a-more-productive-company-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/why-a-hiring-process-built-on-diversity-means-a-more-productive-company.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-why-a-hiring-process-built-on-diversity-means-a-more-productive-company-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-why-you-should-be-using-competency-based-questions-during-the-hiring-process-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/why-you-should-be-using-competency-based-questions-during-the-hiring-process.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-why-you-should-be-using-competency-based-questions-during-the-hiring-process-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-women-in-tech-celebrate-the-achievements-of-women-in-the-technology-sector-and-discuss-challenges-they-face-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/women-in-tech-celebrate-the-achievements-of-women-in-the-technology-sector-and-discuss-challenges-they-face.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-women-in-tech-celebrate-the-achievements-of-women-in-the-technology-sector-and-discuss-challenges-they-face-mdx" */),
  "component---src-components-blog-blog-entry-tsx-content-file-path-blog-zero-trust-networks-redefining-cybersecurity-for-a-perimeter-less-world-mdx": () => import("./../../../src/components/Blog/BlogEntry.tsx?__contentFilePath=/opt/atlassian/pipelines/agent/build/sites/site-v1/blog/zero-trust-networks-redefining-cybersecurity-for-a-perimeter-less-world.mdx" /* webpackChunkName: "component---src-components-blog-blog-entry-tsx-content-file-path-blog-zero-trust-networks-redefining-cybersecurity-for-a-perimeter-less-world-mdx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-job-offers-tsx": () => import("./../../../src/pages/job-offers.tsx" /* webpackChunkName: "component---src-pages-job-offers-tsx" */),
  "component---src-pages-offer-application-development-tsx": () => import("./../../../src/pages/offer/application-development.tsx" /* webpackChunkName: "component---src-pages-offer-application-development-tsx" */),
  "component---src-pages-offer-cloud-consulting-tsx": () => import("./../../../src/pages/offer/cloud-consulting.tsx" /* webpackChunkName: "component---src-pages-offer-cloud-consulting-tsx" */),
  "component---src-pages-offer-digital-transformation-tsx": () => import("./../../../src/pages/offer/digital-transformation.tsx" /* webpackChunkName: "component---src-pages-offer-digital-transformation-tsx" */),
  "component---src-pages-offer-engineering-team-management-tsx": () => import("./../../../src/pages/offer/engineering-team-management.tsx" /* webpackChunkName: "component---src-pages-offer-engineering-team-management-tsx" */),
  "component---src-pages-offer-it-recruitment-tsx": () => import("./../../../src/pages/offer/it-recruitment.tsx" /* webpackChunkName: "component---src-pages-offer-it-recruitment-tsx" */),
  "component---src-pages-offer-it-trainings-tsx": () => import("./../../../src/pages/offer/it-trainings.tsx" /* webpackChunkName: "component---src-pages-offer-it-trainings-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---templates-blog-list-template-tsx": () => import("./../../../templates/BlogListTemplate.tsx" /* webpackChunkName: "component---templates-blog-list-template-tsx" */)
}

