module.exports = [{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-remark-images@7.13.2_gatsby-plugin-sharp@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_esli_yivqnxhqsaywwcfasjbbag3hue/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"markdownCaptions":true,"withWebp":true,"showCaptions":["title"],"maxWidth":650,"linkImagesToOriginal":true,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-intl@5.10.0_react@18.3.1_typescript@5.6.2/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en"],"fallbackLanguage":"en","defaultLanguage":"en","path":"/opt/atlassian/pipelines/agent/build/sites/site-v1/locales/","redirect":false,"redirectDefaultLanguageToRoot":true},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby-plugin-catch-links@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.11.1_jiti@1.21.6__ragvatfwjekoouut4wyknt4oxu/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../plugins/source-traffit/gatsby-browser.js'),
      options: {"plugins":[],"traffitAccountName":"cloudtechconsulting"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../common/temp/node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@9.11.1_jiti@1.21.6___react-dom@18.3.1_react@18.3.1___fjc4s3qdm25f2oa4feisiuzvbu/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
